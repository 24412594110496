import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/compat/firestore"
import { Action, NgxsOnInit, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { UserService } from "src/app/auth/services/user.service";
import { IOutboundOrder, OutboundOrder } from "src/app/core/models/order/outboundOrder"
import { OrgUser } from "src/app/core/models/user/user";
import { OutboundOrdersActions } from "./outbound-orders.action";
import { GlobalActions } from "src/app/core/actions/global.actions";
import { patch } from "@ngxs/store/operators";
import { OrderArticle } from "src/app/core/models/order/orderArticle";
import { firstValueFrom, tap } from "rxjs";

export class OutboundOrdersStateModel {
    outboundOrdersBucket: { [id: string]: OutboundOrder } = {};
    masterArticles: OrderArticle[] = [];
}

@State<OutboundOrdersStateModel>({
    name: 'outboundOrdersState',
    defaults: {
        outboundOrdersBucket: {},
        masterArticles: []
    }
})

@Injectable()
export class OutboundOrdersState implements NgxsOnInit {

    orgId: string = "";
    orgUserId: string = ""

    constructor(
        private userService: UserService,
        private db: AngularFirestore,
        private store: Store
    ) { }

    ngxsOnInit(): void {
        this.userService.orgUserAsObservable().subscribe(
            (user: OrgUser | undefined) => {
                if (!user || !user.id) {
                    return
                }
                this.orgId = user.orgId
                this.orgUserId = user.id
            }
        )
    }

    @Action(OutboundOrdersActions.ConnectOutboundOrdersOnceByOrgUser)
    async connectSupplierOutboundOrders(ctx: StateContext<OutboundOrdersStateModel>,
        action: OutboundOrdersActions.ConnectOutboundOrdersOnceByOrgUser) {
        try {
            const changes: { [id: string]: OutboundOrder } = {};

            const querySnapshot = await firstValueFrom(this.db
                .collection<IOutboundOrder>(`organizations/${this.orgId}/outbound_orders`, (ref) =>
                    ref.where('createdByUserId', '==', this.orgUserId))
                .get());

            querySnapshot.forEach(doc => {
                const orderData = doc.data();
                changes[doc.id] = new OutboundOrder(orderData);
            });

            ctx.patchState({ outboundOrdersBucket: changes });
        } catch (error) {
            console.error("Error fetching outbound orders: ", error);
            // Handle error appropriately
        }
    }

    @Action(OutboundOrdersActions.ConnectOutboundOrdersValueChangesByOrgUser)
    connectOutboundOrders(ctx: StateContext<OutboundOrdersStateModel>,
        action: OutboundOrdersActions.ConnectSupplierOrders) {
        const changes: { [id: string]: OutboundOrder } = {}
        this.db.collection<IOutboundOrder>(`organizations/${this.orgId}/outbound_orders`,
            (ref) => ref.where('createdByUserId', '==', this.orgUserId))
            .valueChanges()
            .subscribe(res => {
                res.forEach(order => {
                    changes[order.id] = new OutboundOrder(order)
                });
                ctx.patchState({ outboundOrdersBucket: changes })
            })
    }



    @Action(OutboundOrdersActions.GetMasterArticles)
    GetMasterArticles(ctx: StateContext<OutboundOrdersStateModel>,
        action: OutboundOrdersActions.GetMasterArticles) {
        //proveriti da li je prazno, ako jeste pozvati api

        return this.db.collection(`organizations/${this.orgId}/catalogs/master/articles`)
            .get()
            .pipe(tap(result => {
                if (result && result.size) {

                    // Only process the first 100 documents
                    const limitedDocs = result.docs.slice(0, 50);
                    const articlesToAdd = limitedDocs.map(articleFromCatalog => {
                        return new OrderArticle(articleFromCatalog.data())

                    });

                    // Update the state with the new list of articles
                    ctx.setState(patch({
                        masterArticles: articlesToAdd
                    }));
                }
            }));

    }





    @Action(OutboundOrdersActions.ConnectOutboundOrder)
    connectOutboundOrder(ctx: StateContext<OutboundOrdersStateModel>,
        action: OutboundOrdersActions.ConnectOutboundOrder) {
        throw new Error('connectSupplierOutboundOrders - not implemented yet');
    }


    @Selector()
    static getOutboundOrders(state: OutboundOrdersStateModel) {
        return Object.values(state.outboundOrdersBucket)
    }

    @Selector()
    static getOutboundOrder(state: OutboundOrdersStateModel) {
        return (orderId: string) => {
            return state.outboundOrdersBucket[orderId]
        }
    }

    @Selector()
    static getRecentOrders(state: OutboundOrdersStateModel) {
        return (userId: string) => {
            return Object.values(state.outboundOrdersBucket).filter((order: OutboundOrder) => order.createdByUserId === userId)
        }
    }

    @Selector()
    static getSupplierOrders(state: OutboundOrdersStateModel) {
        return (supplierId: string) => {
            return Object.values(state.outboundOrdersBucket)
                .filter((order: OutboundOrder) => order.supplier.id === supplierId)
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        }
    }

    @Action(GlobalActions.ClearAll)
    clearState(ctx: StateContext<OutboundOrdersStateModel>, { }: GlobalActions.ClearAll) {
        ctx.setState({
            outboundOrdersBucket: {},
            masterArticles: []
        })
    }

    @Selector()
    static getmasterArticlesFromState(state: OutboundOrdersStateModel) {
        return state.masterArticles
    }

    @Action(OutboundOrdersActions.UpdateOutboundOrder)
    updateOutboundOrder(ctx: StateContext<OutboundOrdersStateModel>,
        action: OutboundOrdersActions.UpdateOutboundOrder) {

        const changes: { [id: string]: OutboundOrder } = {}
        if (action.outboundOrder != undefined) {
            changes[action.outboundOrder.id] = new OutboundOrder(action.outboundOrder)

            ctx.setState(patch({
                outboundOrdersBucket: patch(changes)
            }))
        }

    }


    static getOutboundOrderFromStateById(orderId: string) {
        return createSelector([OutboundOrdersState], (state: OutboundOrdersStateModel) => {
            return state.outboundOrdersBucket[orderId]
        })
    }



}