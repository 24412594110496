import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'firebase/auth';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { InfoDialogComponent } from 'src/app/core/components/info-dialog/info-dialog.component';
import { CustomerLocation } from 'src/app/core/models/customer/customerLocation';
import { ProcurementLocation } from 'src/app/core/models/customer/procurementLocation';
import { InboundOrderSupplierSalesRep } from 'src/app/core/models/order/supplier/inboundOrderSupplierSalesRep';
import { JoinRequest } from 'src/app/core/models/organization/joinRequest';
import { IOrganization, Organization } from 'src/app/core/models/organization/organization';
import { INotificationToken, NotificationToken } from 'src/app/core/models/user/notification-token';
import { OrgUser, UserFS } from 'src/app/core/models/user/user';
import { UserDeliveryLocation } from 'src/app/core/models/user/userDeliveryLocation';

// lifespan = 1 month in miliseconds
const NOTIFICATION_TOKEN_LIFESPAN = 1000 * 60 * 60 * 24 * 30

export const NO_USER_ID = "NO_USER"

export const NO_USER = new UserFS({ id: NO_USER_ID })

export const NO_ORGANIZATION = "NO_ORG_STATUS"
export const NO_TEFTER_ACCOUNT = "NO_TEFTER_ACCOUNT"
export const PENDING_TO_JOIN = "PENDING_TO_JOIN"

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userSubject = new BehaviorSubject<UserFS | undefined>(undefined);
  user$ = this.userSubject.asObservable();

  private orgUserSubject = new BehaviorSubject<OrgUser>(new OrgUser())
  orgUser$ = this.orgUserSubject.asObservable();

  constructor(
    public dialog: MatDialog,
    private db: AngularFirestore,
  ) { }


  getUserByUID(uid: string): Promise<UserFS> {
    const userObservable = this.db
      .collection('users')
      .doc(uid)
      .get()
      .pipe(
        map((user: any) => {
          if (!user.data()) {
            return NO_USER;
          }
          return new UserFS(user.data());
        })
      );

    return firstValueFrom(userObservable);
  }

  async fetchOrgUser(orgId: string, userId: string): Promise<OrgUser> {
    const userSnapshot$ = this.db.collection('organizations')
      .doc(orgId)
      .collection('users')
      .doc(userId)
      .get()
      .pipe(
        map((user: any) => new OrgUser(user.data()))
      );

    return firstValueFrom(userSnapshot$);
  }

  getUserOganization(orgId: string): Observable<Organization> {
    return this.db.collection('organizations')
      .doc(orgId)
      .get()
      .pipe(map((org: any) => new Organization(org.data())))
  }

  getCurrentUser(): UserFS | undefined {
    return this.userSubject.value;
  }

  setCurrentUser(user: UserFS | undefined) {
    this.userSubject.next(user)
  }

  userAsObservable(): Observable<UserFS | undefined> {
    return this.user$
  }

  getOrgUser(): OrgUser {
    return this.orgUserSubject.value;
  }

  setOrgUser(user: OrgUser) {
    this.orgUserSubject.next(user);
  }

  orgUserAsObservable(): Observable<OrgUser> {
    return this.orgUser$;
  }

  saveNotificationToken(token: string): void {
    const currentUser = this.userSubject.value

    if (!currentUser || !currentUser.id) return;

    const retailRef = this.db.collection<NotificationToken>('retail_users').doc(currentUser.id);
    const notifyToken = new NotificationToken((new Date()).toDateString(), token)

    retailRef.get().subscribe((doc) => {
      if (doc.exists) {
        // Proceed with the update
        const _currentNotificationToken: NotificationToken | undefined = doc.data()

        if (_currentNotificationToken) {
          if ((new Date()).getTime() - (new Date(_currentNotificationToken.timestamp).getTime()) > NOTIFICATION_TOKEN_LIFESPAN
            || _currentNotificationToken.token != token) {
            console.log(`NotificationToken update in progress...ID:${currentUser.id},${currentUser.orgId};`)
            this.updateUserDeviceNotificationToken(notifyToken, currentUser.id, currentUser.orgId)
          } else {
            console.log("NotificationToken is already updated...")
          }
        }

      } else {
        // Handle the case where the document does not exist
        console.log("Handle the case where the document does not exist")
        this.updateUserDeviceNotificationToken(notifyToken, currentUser.id, currentUser.orgId)
      }
    })
  }

  updateUserDeviceNotificationToken(notificationToken: NotificationToken, userId: string, currentOrgId: string | undefined) {

    this.db.collection<NotificationToken>('retail_users').doc(userId).set(notificationToken.toFirestore(), { merge: true })
    this.db.collection<NotificationToken>('users').doc(userId).set(notificationToken.toFirestore(), { merge: true })

    if (!currentOrgId) return;
    this.db.collection<NotificationToken>('organizations').doc(currentOrgId)
      .collection('users')
      .doc(userId)
      .set(notificationToken.toFirestore(), { merge: true })
  }


  async removeUserOrganization(orgId: string) {
    const _user = this.getOrgUser()
    if (_user.isSuperAdmin) {
      this.dialog.open(InfoDialogComponent, {
        width: '360px',
        data: { text: "Nije moguce napustiti organizaciju. Vi ste administrator." }
      });
    } else {

      throw new Error("removeUserOrganization() - Not implemented yet")
      // const updatedOrgs = _user.orgIds.filter(org => org !== orgId);
      // const updatedUser = { ..._user, orgIds: updatedOrgs } as UserFS;

      // await this.db.collection('users').doc(_user.id).update({ orgIds: updatedOrgs })
      // this.userSubject.next(updatedUser)
    }

  }


  async updateCurrentUser(userId: string, selectedOrgId: string, claimsArrayOfOrgIds: string[]): Promise<void> {
    const userFromDb = await this.getUserByUID(userId)
    var _newUserFA = new UserFS(userFromDb);

    _newUserFA.orgIds = claimsArrayOfOrgIds
    _newUserFA.orgId = selectedOrgId
    await this.updateCurrentOrganization(_newUserFA.id, selectedOrgId)

    this.setCurrentUser(_newUserFA)

    let _orgUser = await this.fetchOrgUser(_newUserFA.orgId, userId)
    if (_orgUser) {
      this.setOrgUser(_orgUser);
    }

  }

  async updateUserRegistrationStatus(registrationStatus: string) {
    var currUser = this.getCurrentUser()
    if (currUser) {
      await this.db.collection('users').doc(currUser.id).update({
        registrationStatus: registrationStatus
      })
      currUser.registrationStatus = registrationStatus
      this.setCurrentUser(currUser)

    }
  }

  async updateCurrentOrganization(userId: string, orgId: String) {
    await this.db.collection('users').doc(userId).update({
      orgId: orgId
    })
  }

  async createOrganizationJoinRequest(orgId: string, orgName: string) {
    let _user = this.getCurrentUser()
    if (!_user) {
      console.log('Error loading user')
      return
    }

    const currentJoinRequest: JoinRequest | undefined = await this.getCurrentJoinRequest(_user.id, orgId)

    if (currentJoinRequest == undefined) {
      let _newId = this.db.createId();
      let joinRequest = new JoinRequest({
        creationTimestamp: new Date(),
        fullName: _user.fullName,
        isApproved: false,
        isResolved: false,
        orgId: orgId,
        orgName: orgName,
        phoneNumber: _user.phoneNumber,
        userId: _user.id,
        id: _newId
      })

      await this.db.collection('join_requests').doc(_newId).set(joinRequest.toFirestore());
    }

  }

  getCurrentJoinRequest(userId: string, orgId: String): Promise<JoinRequest | undefined> {

    const rjoin = this.db.collection('join_requests',
      (ref) => (ref.where('userId', '==', userId)
        .where('isResolved', '==', false)
        .where('orgId', '==', orgId)
      ))
      .get()
      .pipe(
        map((result) => {
          if (result && result.size) {
            return result.docs[0].data() as JoinRequest;
          }
          return undefined
        })
      );

    return firstValueFrom(rjoin);

  }



  getUserSalesrepObject(): InboundOrderSupplierSalesRep {

    var salesRepSupp = {
      "name": this.getOrgUser().fullName,
      "id": this.getOrgUser().id,
      "externalReferenceCode": this.getOrgUser().externalReferenceCode
    }

    return new InboundOrderSupplierSalesRep(salesRepSupp)
  }

  async getAllUserDeliveryLocations(): Promise<ProcurementLocation[]> {
    let locationIdArray = this.getOrgUser().locations
    const articlePromises = locationIdArray.map(async (locationId) => {
      const doc = await firstValueFrom(this.db.doc<any>(`organizations/${this.getOrgUser().orgId}/locations/${locationId}`).get());
      return new ProcurementLocation(doc.data())
    });

    return Promise.all(articlePromises);
  }


  // Method to get originCode by externalCode
  async getERPDeliveryLocationByCustomerLocationId(locationId: string, supplierId: string): Promise<string | null> {
    const docRef = this.db.doc(
      `organizations/${this.getOrgUser().orgId}/tefter_suppliers/${supplierId}`
    );

    const doc = await docRef.ref.get();

    if (doc.exists) {
      const data = doc.data() as any; // Using 'any' to bypass strict type checking
      const locationMapper = data?.locationMapper;
      if (Array.isArray(locationMapper)) {
        const location = locationMapper.find((lm: any) => lm.externalRefCode === locationId);
        if (location) {
          return location.originExternalRefCode;
        } else {
          throw new Error(`No matching originCode found for externalCode: ${locationId}`);
        }
      } else {
        throw new Error('locationMapper is not an array or is missing');
      }
    } else {
      throw new Error(`No document found at path: organizations/${this.getOrgUser().orgId}/tefter_suppliers/${supplierId}`);
    }
  }
}