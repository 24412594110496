<div id="recaptcha-container"></div>
<div class="container">
    <mat-card>
        <div class="auth-form-progress">
            <mat-progress-bar *ngIf="showProgress" mode="indeterminate"></mat-progress-bar>
        </div>
        <img src="assets/images/Transparent-Logo.png" alt="Logo" class="logo">
        <p class="mat-headline-5">Prijavite se</p>
        <mat-card-content>
            <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">

                <mat-grid-list cols="2" rowHeight="1:1">
                    <mat-grid-tile>
                        <app-country-code-selector (codeSelected)="countryCodeSelected($event)"
                            [displayCallingCode]="true"></app-country-code-selector> <!--  [disableControl]="phoneSubmitted" -->
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field appearance="fill">
                            <mat-label>Broj telefona</mat-label>
                            <input matInput formControlName="phoneNumber" autocomplete="off" autofocus
                                inputmode="numeric" placeholder="Broj telefona">
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>

                <span *ngIf="phoneSubmitted">
                    <span class="resend-link" (click)="resendCode()">
                        <strong>Izmeni telefon</strong>
                    </span>
                    <p>SMS poruka sa 6-cifrenim verifikacionim kodom je upravo poslata na broj
                        <strong>{{loginForm.get('phoneNumber')?.value}}</strong>
                    </p>
                    <mat-form-field>
                        <mat-label>Verifikacioni kod</mat-label>
                        <input matInput name="verificationCode" placeholder="XXXXXX" formControlName="verificationCode"
                            #codeField autocomplete="off" inputmode="numeric">
                        <mat-error *ngIf="verificationCode.dirty && verificationCode.hasError('required')">Ovo
                            polje je obavezno</mat-error>
                        <mat-error *ngIf="verificationCode.dirty && verificationCode.hasError('pattern')">Ovo
                            polje mora
                            sadrzati
                            6
                            cifara</mat-error>
                    </mat-form-field>

                    <div class="resend-block">
                        <p>Kod nije stigao?
                            <span class="resend-link" (click)="resendCode()">
                                <strong>Pošalji ponovo</strong>
                            </span>
                        </p>
                    </div>
                </span>
                <button mat-raised-button color="primary" type="submit" [disabled]="disableSubmitButton()">
                    Potvrdi
                </button>
            </form>
        </mat-card-content>
    </mat-card>
</div>