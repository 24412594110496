import { Injectable, OnDestroy } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection, Query } from "@angular/fire/compat/firestore"
// import { Disconnect, Emitted, NgxsFirestoreConnect, StreamEmitted } from "@ngxs-labs/firestore-plugin"
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from "@ngxs/store"
import { append, insertItem, patch } from "@ngxs/store/operators"
import { catchError, map, switchMap, tap } from "rxjs/operators"
import { UserService } from "src/app/auth/services/user.service"
import { IInboundOrder, InboundOrder } from "src/app/core/models/order/inboundOrder"
import { OrgUser } from "src/app/core/models/user/user"
import { InboundOrdersActions } from "./inbound-orders.action"
// import { InboundOrdersFirestore } from "./firestore/inbound-orders.firestore"
import { InboundOrdersTypesense, InboundOrderTS, InboundOrderTypesenseResponse } from "./inbound-orders.typesense"
import { firstValueFrom, Observable, Subscription } from "rxjs"
import { GlobalActions } from "src/app/core/actions/global.actions"
// import { InboundOrdersFirestore } from "./firestore/inbound-orders.firestore"
// import { InboundOrderHisotry } from "./firestore/inbound-order-history.firestore"

export class InboundOrdersStateModel {
  inboundOrdersBucket: { [id: string]: InboundOrder } = {};
  searchResult: string[] = [];
  totalFound: number = 0;
  totalPages: number = 0;
  outOf: number = 0;
  currentPageLoaded: number = 1;
  inboundOrderHistory: InboundOrder[] = []
}

@State<InboundOrdersStateModel>({
  name: 'inboundOrdersState',
  defaults: {
    inboundOrdersBucket: {},
    searchResult: [],
    totalFound: 0,
    totalPages: 0,
    outOf: 0,
    currentPageLoaded: 1,
    inboundOrderHistory: []
  }
})
@Injectable()
export class InboundOrdersState implements NgxsOnInit, OnDestroy {

  orgId: string = "";
  orgUserId: string = ""

  globalWarehouseInboundOrderSubs: Subscription | undefined;
  globalSalesrepInboundOrderSubs: Subscription | undefined;

  orderHistorySubscription: Subscription | undefined;

  constructor(
    private userService: UserService,
    // private ngxsFirestoreConnect: NgxsFirestoreConnect,
    // private inboundOrdersFS: InboundOrdersFirestore,
    // private inboundOrderHistoryFS: InboundOrderHisotry,
    private store: Store,
    private db: AngularFirestore,
    private typesense: InboundOrdersTypesense
  ) {
  }


  ngOnDestroy(): void {
    this.globalSalesrepInboundOrderSubs?.unsubscribe()
    this.globalWarehouseInboundOrderSubs?.unsubscribe()
  }

  ngxsOnInit(): void {
    this.userService.orgUserAsObservable().subscribe(
      (user: OrgUser | undefined) => {
        if (!user || !user.id) {
          return
        }

        this.orgId = user.orgId
        this.orgUserId = user.id

      }
    )
  }


  // @Action(InboundOrdersActions.WarehouseConnectInboundOrders)
  // warehouseConnectInboundOrders(ctx: StateContext<InboundOrdersStateModel>,
  //   action: InboundOrdersActions.WarehouseConnectInboundOrders) {

  //   // Unsubscribe from the previous subscription, if it exists
  //   if (this.globalWarehouseInboundOrderSubs) {
  //     this.globalWarehouseInboundOrderSubs.unsubscribe();
  //   }
  //   // const state = ctx.getState();

  //   this.globalWarehouseInboundOrderSubs = this.db.collection<InboundOrder>(`/organizations/${this.orgId}/inbound_orders`, (ref) =>
  //     ref.where('requestedDeliveryDate', '==', action.date)
  //       //.orderBy('lastModifiedAt', 'desc')
  //       .orderBy('createdAt', 'desc').limit(20)
  //   ).valueChanges()
  //     .subscribe((res) => {

  //       res.forEach((order) => {
  //         var changes: { [id: string]: InboundOrder } = {}
  //         changes[order.id] = new InboundOrder(order)
  //         console.log("osvezen order:", order)
  //         ctx.setState(patch({
  //           inboundOrdersBucket: changes,
  //         }));
  //         // if (!searchResult.includes(order.id)) {
  //         //   ctx.setState(patch({
  //         //     searchResult: insertItem(order.id)
  //         //   }))
  //         // }
  //       });
  //     });
  // }



  @Action(InboundOrdersActions.UpdateInboundOrderBucket)
  updateInboundOrderBucket(ctx: StateContext<InboundOrdersStateModel>,
    action: InboundOrdersActions.UpdateInboundOrderBucket) {
    const state = ctx.getState();

    let newInboundOrdersBucket = { ...state.inboundOrdersBucket };
    let newSearchResult = [...state.searchResult];

    action.updatedOrderList.forEach(order => {
      newInboundOrdersBucket[order.id] = order;

      if (!newSearchResult.includes(order.id)) {
        newSearchResult.push(order.id);
      }
    });

    ctx.setState(
      patch({
        inboundOrdersBucket: newInboundOrdersBucket,
        searchResult: newSearchResult
      })
    );
  }


  @Action(InboundOrdersActions.ConnectTypesenseInboundOrders)
  connectTypesenseInboundOrders(ctx: StateContext<InboundOrdersStateModel>, { payload }: InboundOrdersActions.ConnectTypesenseInboundOrders) {
    const bucket = ctx.getState().inboundOrdersBucket

    const sub = this.typesense.search(payload).pipe(
      tap(() => ctx.setState(patch({ searchResult: [] }))),
      tap((ordersTS: InboundOrderTypesenseResponse) => {
        ctx.setState(patch({
          searchResult: append(ordersTS.results.map((order: InboundOrderTS) => order.id)),
          totalPages: ordersTS.totalPages,
          totalFound: ordersTS.found,
          outOf: ordersTS.out_of,
          currentPageLoaded: ordersTS.currentPage
        }))
      }),
      tap((orders: InboundOrderTypesenseResponse) => {
        orders.results.forEach((order: InboundOrderTS) => {
          if (!bucket[order.id]) {
            this.store.dispatch(new InboundOrdersActions.ConnectInboundOrder(order.id))
          }
        })
      }),
      tap(() => {
        sub.unsubscribe()
      })
    ).subscribe()
  }

  @Action(InboundOrdersActions.ConnectTypesenseInboundOrdersNextPage)
  ConnectTypesenseInboundOrdersNextPage(ctx: StateContext<InboundOrdersStateModel>,
    { payload }: InboundOrdersActions.ConnectTypesenseInboundOrders) {

    const currentState = ctx.getState()

    return this.typesense.search(payload).pipe(
      tap((ordersTS: InboundOrderTypesenseResponse) => {

        const idArray = ordersTS.results.map((order) => order.id);
        ctx.setState(patch({
          searchResult: currentState.searchResult.concat(idArray),
          totalPages: ordersTS.totalPages,
          totalFound: ordersTS.found,
          outOf: ordersTS.out_of,
          currentPageLoaded: ordersTS.currentPage
        }))
      }),
      tap((orders: InboundOrderTypesenseResponse) => {
        // console.log("orders.results:", orders.results)
        orders.results.forEach((order: InboundOrderTS) => {
          if (!currentState.inboundOrdersBucket[order.id]) {
            this.store.dispatch(new InboundOrdersActions.ConnectInboundOrder(order.id))
          }
        })
      })
    )
  }

  @Action(InboundOrdersActions.ConnectAllTypesenseInboundOrders)
  async ConnectAllTypesenseInboundOrders(ctx: StateContext<InboundOrdersStateModel>, { payload }: InboundOrdersActions.ConnectTypesenseInboundOrders) {
    const fetchAllPages = async () => {
      let allOrders: string[] = [];
      let currentPage = 1;
      let totalPages = 1; // Initialize totalPages; it will be updated later

      do {
        try {
          const ordersTS = await firstValueFrom(
            this.typesense.search({ ...payload, page: currentPage }).pipe(
              catchError((error) => {
                console.error('Error fetching from Typesense:', error);
                return []; // Return an empty array or handle as needed
              })
            )
          );

          if (ordersTS && ordersTS.results) {
            const idArray = ordersTS.results.map((order) => order.id);
            allOrders = allOrders.concat(idArray);

            // Update the state with current fetched orders
            ctx.setState((state) => ({
              ...state,
              searchResult: allOrders,
              totalPages: ordersTS.totalPages,
              totalFound: ordersTS.found,
              outOf: ordersTS.out_of,
              currentPageLoaded: ordersTS.currentPage
            }));

            ordersTS.results.forEach((order: InboundOrderTS) => {
              if (!ctx.getState().inboundOrdersBucket[order.id]) {
                this.store.dispatch(new InboundOrdersActions.ConnectInboundOrder(order.id));
              }
            });

            totalPages = ordersTS.totalPages; // Update totalPages from the response
          }
          currentPage++; // Increment to the next page

        } catch (error) {
          console.error('An error occurred while fetching orders:', error);
          break; // Break the loop in case of errors
        }
      } while (currentPage <= totalPages);
    };

    // Execute the async operation to fetch all pages
    await fetchAllPages();
  }

  @Action(InboundOrdersActions.removeInboundOrder)
  removeInboundOrderByCustomerId(ctx: StateContext<InboundOrdersStateModel>, action: InboundOrdersActions.removeInboundOrder) {
    // const state = ctx.getState();
    // const updatedBucket = Object.fromEntries(
    //   Object.entries(state.inboundOrdersBucket).filter(
    //     ([key, order]) => order.customer.id !== action.customerId
    //   )
    // );

    // ctx.setState({
    //   ...state,
    //   inboundOrdersBucket: updatedBucket
    // });

    const state = ctx.getState();
    const updatedBucket = Object.keys(state.inboundOrdersBucket).reduce((acc, key) => {
      const order = state.inboundOrdersBucket[key];
      if (order.customer.id !== action.customerId) {
        acc[key] = order;
      }
      return acc;
    }, {} as Record<string, InboundOrder>);

    ctx.setState({
      ...state,
      inboundOrdersBucket: updatedBucket
    });
  }

  @Action(InboundOrdersActions.UpdateInboundOrder)
  updateInboundOrder(ctx: StateContext<InboundOrdersStateModel>,
    action: InboundOrdersActions.UpdateInboundOrder) {

    const changes: { [id: string]: InboundOrder } = {}
    if (action.inboundOrder != undefined) {
      changes[action.inboundOrder.id] = new InboundOrder(action.inboundOrder)
      ctx.setState(patch({
        inboundOrdersBucket: patch(changes)
      }))
    }

  }


  @Action(InboundOrdersActions.ConnectInboundOrder)
  connectInboundOrder(ctx: StateContext<InboundOrdersStateModel>,
    action: InboundOrdersActions.ConnectInboundOrder) {
    return this.db.doc<InboundOrder>(`organizations/${this.orgId}/inbound_orders/${action.orderId}`)
      .get()
      .pipe(tap(result => {

        const changes: { [id: string]: InboundOrder } = {}
        if (result.data() != undefined) {
          changes[action.orderId] = new InboundOrder(result.data())
          // changes[action.orderId] = result.data()!;

          ctx.setState(patch({
            inboundOrdersBucket: patch(changes)
          }))
          // if (payload.status.code === 'INVOICED') {
          //     this.store.dispatch(new Disconnect(new InboundOrdersActions.ConnectInboundOrder(payload.id)))
          // }
        }

      }));
  }

  @Action(InboundOrdersActions.ConnectInboundOrderHistory)
  connectInboundOrderHistory(ctx: StateContext<InboundOrdersStateModel>,
    action: InboundOrdersActions.ConnectInboundOrderHistory) {

    //prepakovati da vraca kao promis ne valueChange
    if (this.orderHistorySubscription) {
      this.orderHistorySubscription.unsubscribe();
    }

    console.log(`history za: organizations/${this.orgId}/inbound_orders/${action.orderId}/change_log`)
    this.orderHistorySubscription = this.db.collection<InboundOrder>(`organizations/${this.orgId}/inbound_orders/${action.orderId}/change_log`,
      (ref) => ref.orderBy('lastModifiedAt', 'desc')
    ).valueChanges()
      .subscribe((res) => {

        var inboundOrdersArray: InboundOrder[] = []
        res.forEach((order: InboundOrder) => {
          inboundOrdersArray.push(order)
        });

        ctx.setState(patch({ inboundOrderHistory: inboundOrdersArray }))
      });

  }

  @Action(InboundOrdersActions.ResetSearchResult)
  resetSearchResult(ctx: StateContext<InboundOrdersStateModel>) {
    ctx.setState(patch({
      searchResult: new Array(),
      totalPages: 0,
      totalFound: 0,
      outOf: 0,
      currentPageLoaded: 1,
    }))
    //console.log("orbisao state za search")
  }

  @Selector()
  static getInboundOrders(state: InboundOrdersStateModel) {
    return Object.values(state.inboundOrdersBucket)
  }

  @Selector()
  static getInboundOrder(state: InboundOrdersStateModel) {
    return (orderId: string) => {
      return state.inboundOrdersBucket[orderId]
    }
  }

  static getOrderFromStateById(orderId: string) {
    return createSelector([InboundOrdersState], (state: InboundOrdersStateModel) => {
      return state.inboundOrdersBucket[orderId]
    })
  }


  @Selector()
  static getRecentOrders(state: InboundOrdersStateModel) {
    return (userId: string) => {
      return Object.values(state.inboundOrdersBucket).filter((order: InboundOrder) => order.createdByUserId === userId)
    }
  }

  @Selector()
  static getSearchedOrders(state: InboundOrdersStateModel) {
    return state.searchResult.map((id: string) => state.inboundOrdersBucket[id])
  }


  @Selector()
  static getInboundOrderHistory(state: InboundOrdersStateModel) {
    return state.inboundOrderHistory
  }

  @Selector()
  static getSearchResult(state: InboundOrdersStateModel) {
    return state.searchResult
  }


  @Selector()
  static getTotalOrdersPages(state: InboundOrdersStateModel) {
    return state.totalPages
  }

  @Selector()
  static getCustomerOrdersNextPage(state: InboundOrdersStateModel) {
    return state.currentPageLoaded + 1
  }


  @Action(GlobalActions.ClearAll)
  clearState(ctx: StateContext<InboundOrdersStateModel>, { }: GlobalActions.ClearAll) {
    ctx.setState({
      inboundOrdersBucket: {},
      searchResult: [],
      totalFound: 0,
      totalPages: 0,
      outOf: 0,
      currentPageLoaded: 1,
      inboundOrderHistory: []
    })
  }



  // Dynamic Selector to get inbound orders by a specific customer ID
  static inboundOrdersByCustomerId(customerId: string) {
    return createSelector([InboundOrdersState], (state: InboundOrdersStateModel) => {
      return Object.values(state.inboundOrdersBucket)
        .filter(order => order.customer.id === customerId)
        .sort((a, b) => b.lastModifiedAt.getTime() - a.lastModifiedAt.getTime())
    });
  }





}
