import { InboundOrder } from "src/app/core/models/order/inboundOrder";
import { SearchParams } from "typesense/lib/Typesense/Documents";

export namespace InboundOrdersPayloads {
  //export type ConnectInboundOrders = Partial<{ module: string, date: Date }>;
  export type ConnectInboundOrders = Date;
  export type ConnectTypesenseInboundOrders = SearchParams
  export type ConnectInboundOrder = string;
  export type ConnectInboundOrderHistory = string;
}

export namespace InboundOrdersActions {

  export class WarehouseConnectInboundOrders {
    public static readonly type = '[InboundOrdersActions] WarehouseConnectInboundOrders'
    constructor(public date: InboundOrdersPayloads.ConnectInboundOrders) { }
  }

  // export class SalesrepConnectInboundOrders {
  //   public static readonly type = '[InboundOrdersActions] SalesrepConnectInboundOrders'
  //   constructor() { }
  // }

  export class UpdateInboundOrderBucket {
    public static readonly type = '[InboundOrdersActions] UpdateInboundOrderBucket'
    constructor(public updatedOrderList: InboundOrder[]) { }
  }

  export class UpdateInboundOrder {
    public static readonly type = '[InboundOrdersActions] UpdateInboundOrder'
    constructor(public inboundOrder: InboundOrder) { }
  }

  export class removeInboundOrder {
    public static readonly type = '[InboundOrdersActions] removeInboundOrder'
    constructor(public customerId: string) { }
  }

  // export class SalesrepConnectInboundOrdersForSpecificCustomer {
  //   public static readonly type = '[InboundOrdersActions] SalesrepConnectInboundOrdersForSpecificCustomer'
  //   constructor(public customerId: string) { }
  // }

  export class ConnectTypesenseInboundOrders {
    public static readonly type = '[InboundOrdersActions] ConnectTypesenseInboundOrders'
    constructor(public payload: InboundOrdersPayloads.ConnectTypesenseInboundOrders) { }
  }

  export class ConnectTypesenseInboundOrdersNextPage {
    public static readonly type = '[InboundOrdersActions] ConnectTypesenseInboundOrdersNextPage'
    constructor(public payload: InboundOrdersPayloads.ConnectTypesenseInboundOrders) { }
  }

  export class ConnectAllTypesenseInboundOrders {
    public static readonly type = '[InboundOrdersActions] ConnectAllTypesenseInboundOrders'
    constructor(public payload: InboundOrdersPayloads.ConnectTypesenseInboundOrders) { }
  }

  export class GetNextPage {
    public static readonly type = '[InboundOrdersActions] GetNextPage'
    constructor(public payload: InboundOrdersPayloads.ConnectTypesenseInboundOrders) { }
  }

  export class ConnectInboundOrder {
    public static readonly type = '[InboundOrdersActions] ConnectInboundOrder'
    //constructor(public payload: InboundOrdersPayloads.ConnectInboundOrder){}
    constructor(public orderId: string) { }
  }

  export class ConnectInboundOrderHistory {
    public static readonly type = '[InboundOrdersActions] ConnectInboundOrderHistory'
    //constructor(public payload: InboundOrdersPayloads.ConnectInboundOrderHistory){}
    constructor(public orderId: string) { }
  }

  export class ResetSearchResult {
    public static readonly type = '[InboundOrdersActions] ResetSearchResult'
  }
}
