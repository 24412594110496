import { OrderCustomerDeliveryLocation } from "../order/customer/orderCustomerDeliveryLocation";

export type ApprovedSupplier = {
    externalReferenceCode: string;
    id: string;
};

export class ProcurementLocation {
    id: string;
    name: string;
    externalReferenceCode: string;
    foreignExternalReferenceCode: string;
    subregion: { externalReferenceCode: string };
    approvedSuppliers: ApprovedSupplier[];

    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.externalReferenceCode = obj && obj.externalReferenceCode || "";
        this.foreignExternalReferenceCode = obj && obj.foreignExternalReferenceCode || "";
        this.name = obj && obj.name || "";
        this.subregion = obj && obj.subregion || { externalReferenceCode: "" };
        // Initialize approvedSuppliers with an empty array if not provided
        this.approvedSuppliers = obj && obj.approvedSuppliers || [];
    }

    toFirestore(): any {
        return {
            id: this.id,
            externalReferenceCode: this.externalReferenceCode,
            foreignExternalReferenceCode: this.foreignExternalReferenceCode,
            name: this.name,
            subregion: this.subregion,
            approvedSuppliers: this.approvedSuppliers
        };
    }

    static fromFirestore(snapshot: any, options: any): ProcurementLocation {
        const data = snapshot.data(options);
        return new ProcurementLocation(data);
    }

    toOrderCustomerDeliveryLocation(): OrderCustomerDeliveryLocation {
        return new OrderCustomerDeliveryLocation({
            fullAddressText: this.name,
            externalReferenceCode: this.externalReferenceCode,
            foreignExternalReferenceCode: this.foreignExternalReferenceCode,
            id: this.id,
            name: this.name,
            approvedSuppliers: this.approvedSuppliers
        });
    }


}